import { memo, useCallback, useMemo } from 'react'
import { Avatar } from '../../../atoms/index.js'
import { GuaranteesWithDeductibleList } from '../guarantees-deductible-list/index.js'

import type { UiContext } from '../../../../hooks/use-screen-variant.js'
import { Button } from '../../../button/index.js'
import { PassthroughIcon } from '../passthrough-icon.js'
import type { Option } from '../types.js'

type OnAddOrRemoveClick = (option: Option, type: 'add' | 'remove') => void

export type OptionGuaranteeListProps = {
  options: Option[]
  variant: UiContext
  onOptionAddOrRemoveClick?: OnAddOrRemoveClick
  onInfoOptionClick: (option: Option) => void
  className?: string
  quotePageType: 'static' | 'dynamic'
  isMuta: boolean
}

export const OptionGuaranteeList = memo<OptionGuaranteeListProps>(function GuaranteesList(props) {
  const { options, variant, onOptionAddOrRemoveClick, onInfoOptionClick, className, quotePageType, isMuta } = props

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const handleClick = useCallback(
    (option: Option) => {
      onOptionAddOrRemoveClick?.(option, option.selected ? 'remove' : 'add')
    },
    [onOptionAddOrRemoveClick],
  )

  const optionalGuaranteesWithButton = useMemo(
    () =>
      options.map((option) => ({
        button: (
          <>
            {onOptionAddOrRemoveClick && quotePageType === 'dynamic' ? (
              <Button
                size={variant !== 'desktop' ? 'large' : 'medium'}
                variant={option.selected ? 'secondary' : 'primary'}
                avatar={<Avatar icon={option.selected ? 'trash-can-regular' : 'plus-solid'} />}
                avatarPosition="left"
                onClick={(event: React.MouseEvent) => {
                  event.stopPropagation()
                  handleClick(option)
                }}
              />
            ) : (
              <PassthroughIcon icon="circle-info-solid" />
            )}
          </>
        ),
        onClick: () => {
          onInfoOptionClick(option)
        },
        ...option,
      })),
    [options, onOptionAddOrRemoveClick, quotePageType, variant, handleClick, onInfoOptionClick],
  )

  return (
    <GuaranteesWithDeductibleList
      guarantees={optionalGuaranteesWithButton}
      variant={variant}
      className={className}
      quotePageType={quotePageType}
      isMuta={isMuta}
    />
  )
})
