import { css } from '@emotion/react'
import { memo } from 'react'
import { Card, colorTokens, useScreenType, useSkin, useTranslate } from '../../../../index.js'
import { ButtonLink, PhoneNumber } from '../../../index.js'
import type { HelpContactDetails } from '../../quote-v3/help-block/index.js'
import { OrusFaceAvatar } from '../orus-face-avatar/index.js'

export type ContactBannerProps = {
  helpContactDetails: HelpContactDetails
  variant?: 'branded' | 'neutral'
}

export const ContactBanner = memo<ContactBannerProps>(function ContactBanner(props) {
  const translate = useTranslate()
  const { helpContactDetails, variant = 'branded' } = props
  const screenType = useScreenType()
  const skin = useSkin()

  return (
    <Card
      title={translate('subscription_need_help_title')}
      titleColor={colorTokens['color-text-decorative-main']}
      subtitleColor={colorTokens['color-text-decorative-basic']}
      subtitle={
        variant === 'branded'
          ? translate('subscription_need_help_explanation')
          : translate('subscription_need_help_explanation_broker')
      }
      avatar={variant === 'branded' ? <OrusFaceAvatar variant="round" size="60" /> : undefined}
      backgroundColor={skin.quoteCardHelperBackgroundColor ?? colorTokens['color-bg-decorative-2']}
      fullwidth
    >
      {helpContactDetails.phone ? (
        <ButtonLink
          variant="primary"
          size={screenType === 'desktop' ? 'medium' : 'large'}
          fullWidth
          icon="phone-regular"
          avatarPosition="left"
          to={`tel:${helpContactDetails.phone}`}
          trackingId="call_button"
        >
          <PhoneNumber>{helpContactDetails.phone.replace(/^\+33 ?/, '0')}</PhoneNumber>
        </ButtonLink>
      ) : null}

      {variant === 'branded' ? (
        <ButtonLink
          css={css`
            color: ${colorTokens['color-text-base-secondary']};
          `}
          variant="secondary"
          size={screenType === 'desktop' ? 'medium' : 'large'}
          fullWidth
          avatarPosition="left"
          icon="whatsapp-brands"
          target="_blank"
          to={helpContactDetails.whatsappLink}
        >
          {translate('chat_on_whatsapp')}
        </ButtonLink>
      ) : null}

      {helpContactDetails.email && variant === 'neutral' ? (
        <ButtonLink
          css={css`
            color: ${colorTokens['color-text-base-secondary']};
          `}
          variant="secondary"
          size={screenType === 'desktop' ? 'medium' : 'large'}
          fullWidth
          avatarPosition="left"
          icon="envelope-regular"
          target="_blank"
          to={`email:${helpContactDetails.email}`}
        >
          {translate('send_an_email')}
        </ButtonLink>
      ) : null}
    </Card>
  )
})
