import { css } from '@emotion/react'
import { memo, type ReactNode } from 'react'
import { colors } from '../../../../colors.js'

import styled from '@emotion/styled'
import { shadow } from '../../../../foundation/shadow-tokens.js'
import { spacing } from '../../../../foundation/spacing-tokens.js'
import type { UiContext } from '../../../../hooks/use-screen-variant.js'
import { Text } from '../../../atoms/index.js'

export type BlockProps = {
  variant: UiContext

  title: ReactNode
  description?: string
  rightButton?: ReactNode

  children: ReactNode
  className?: string
}

export const Block = memo<BlockProps>(function Block(props) {
  const { variant, title, description, children, className } = props

  return (
    <Container variant={variant} className={className}>
      <div
        css={css`
          display: flex;
          justify-content: space-between;
        `}
      >
        <div>
          <Text variant="subtitle1">{title}</Text>

          {description ? (
            <Text
              variant="body2"
              css={css`
                margin-top: ${spacing[30]};
              `}
            >
              {description}
            </Text>
          ) : (
            <></>
          )}
        </div>

        {props.rightButton}
      </div>

      {children}
    </Container>
  )
})

const Container = styled.div<{ variant: UiContext }>`
  display: flex;
  flex-direction: column;

  padding: ${(props) => (props.variant === 'mobile' || props.variant === 'backoffice' ? spacing[60] : spacing[70])};

  background-color: ${colors.white};
  border-radius: ${(props) => (props.variant === 'mobile' || props.variant === 'backoffice' ? '0px' : '12px')};

  box-shadow: ${shadow.bottom[20]};
`
